import React, {useContext} from 'react'

// import Layout from '@components/Layout'
import Layout from '../components/Layout'
import Image from '../components/Image'
import { t } from '../components/Translator'
import { AppContext } from '../components/ContextProvider'

// @ts-ignore
import content from '../../content/contact.yaml'

const styles = {
    marginTop_10: {
        marginTop: '10px',
    }
}

const ContactPage: React.FC = () => {

    const context = useContext(AppContext)

    // (!) undefined checks to enable 'gatsby build'
    let locale = 'nl'
    if(!context || !context.locale) {
        // console.error(`# ContactPage ${!context ? 'context' : 'context locale'} undefined`)
    }
    else {
        locale = context.locale.lang.toLowerCase()
    }

    // console.log(`# ContactPage locale == ${locale}`)

    const images = content.images
    const liturgy = content.contact_liturgy
    const cathedral = content.contact_cathedral
    const council = content.contact_council
    const form = content.contact_form

    const validMessage = t(form.validation.message)
    const validName = t(form.validation.name)
    const validEmail = t(form.validation.email)

    const validate = (e: any, message: string) => {
        // (!) e.preventDefault() blocks validation
        // console.log(`# ContactPage validate message == ${message}`)
        e.target.setCustomValidity(message)
    }

    return (
        <Layout head={content.head} banner={content.banner}>
            <div className="row">
                <div className="col-md-6">
                    <Image image={images[0]}/>
                    <div className="spacer-40"></div>
                </div>
                <div className="col-md-6">
                    <div className="contact-badge right-caret-ul">
                        <h2>{t(liturgy.title)}</h2>
                        <ul style={{marginBottom: 0}}>
                            <li style={{marginTop: '20px'}}>
                                <a href="https://www.kerkinbrugge.be/?id=32" target="_blank">{t(liturgy.pastores)}</a>
                            </li>
                            <li style={styles.marginTop_10}>
                                <a href="https://www.kerkinbrugge.be/?id=33" target="_blank">{t(liturgy.secretariat)}</a>
                            </li>
                        </ul>
                    </div>
                    <div className="spacer-20"></div>
                    <div className="contact-badge">
                        <h2>{t(cathedral.title)}</h2>
                        <div style={styles.marginTop_10}>
                            <span><i className="fa fa-user"></i>{t(cathedral.coordinator)}</span>
                        </div>
                        <div style={styles.marginTop_10}>
                            <span><i className="fa fa-envelope"></i><a href={`mailto:${cathedral.email}`}>{cathedral.email}</a></span>
                        </div>
                        <div style={styles.marginTop_10}>
                            <span><i className="fa fa-phone"></i>{cathedral.phone}</span>
                        </div>
                    </div>
                    <div className="spacer-20"></div>
                    <div className="contact-badge">
                        <h2 className="short2">{t(council.title)}</h2>
                        <div style={styles.marginTop_10}>
							<span>
								<a href="https://www.google.be/maps/place/Sint-Salvatorskathedraal/@51.20552,3.2194807,17z/data=!3m1!4b1!4m5!3m4!1s0x47c350daffda0ecd:0x42d496e81d83e0a!8m2!3d51.2055167!4d3.2216694"
                                   target="_blank"><i className="fa fa-map-marker"></i>
                                    {t(council.address)}
								</a>
							</span>
                        </div>
                        <div style={styles.marginTop_10}>
                            <span><i className="fa fa-envelope"></i><a href={`mailto:${council.email}`}>{council.email}</a></span>
                        </div>
                    </div>
                    <div className="spacer-40"></div>
                    <div>
                        <h2>{t(form.title)}</h2>
                        <div className="spacer-10"></div>
                        {/*
                            ORIG PHP: <form method="post" id="contactform" name="contactform" class="contact-form clearfix" action="mail/contact.php">
                            DISABLE: <form class="contact-form clearfix" onsubmit="alert('De mailfunctie is nog niet geactiveerd in de huidige testversie van de website.');return false;">
                            TEST 22/1/2021 OK - demytter@hotmail.com - new form - action="https://formspree.io/f/xzbkkall"
                            TODO:
                                • xoqprlpz to accept in reply by info@sintsalvator.be
                                • test in PROD NL/FR/EN
                        */}
                        <form id="contactform" action="https://formspree.io/f/xoqprlpz" method="POST">
                            <input type="hidden" name="_language" value={locale}/>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <input type="hidden" name="_subject" value={t(form.subject)}/>
                                        <textarea style={{resize:'vertical'}} rows={7} name="message" className="form-control input-lg"
                                                  placeholder={t(form.placeholder.message)} required
                                                  onInvalid={(e) => validate(e, validMessage)}
                                                  onInput={(e) => validate(e, '')}>
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="text" name="name" className="form-control input-lg"
                                               placeholder={t(form.placeholder.name)} required
                                               onInvalid={(e) => validate(e, validName)}
                                               onInput={(e) => validate(e, '')}/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="email" name="_replyto" className="form-control input-lg"
                                               placeholder={t(form.placeholder.email)} required
                                               onInvalid={(e) => validate(e, validEmail)}
                                               onInput={(e) => validate(e, '')}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <input type="text" name="_gotcha" style={{display:'none'}}/>
                                    <input type="submit" className="btn btn-primary pull-right" value={t(form.send)}/>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ContactPage